<template>
  <div></div>
</template>

<script>
export default {
  name: "Avatars",

  data() {
    return {};
  },
  watch: {},
  async created() {
    const self = this;
    this.axios({
      method: "post",
      url: this.$hostname_ba + "/get_avatars_url",
      params: {
        token: "3f151f38-ccad-41ec-8266-a028b17b0133",
      },
    }).then(function(response) {
      window.location.href = response.data["url"];
    });
  },
  methods: {},
};
</script>
